@media screen and (max-width:1750px) {
    .select-main .select-box h2 span {
        font-size: 20px;
    }
    .total-payout-main .total b {
        font-size: 30px;
    }
    .total-payout-main .total {
        height: fit-content;
    }
    .total-payout-main {
        align-items: flex-end;
    }
    .total-payout-main .total .head h3 {
        font-size: 16px;
    }
    .total-payout-main .total .head {
        padding: 3px 0;
    }
    .select-main .select-box ul.arrow-btns li.number-count {
        font-size: 25px;
    }
    .select-main .select-box ul.arrow-btns li.number-count {
        padding: 10px 15px;
    }
    .select-main .select-box ul {
        padding: 7px 15px;
    }
    .select-main .select-box ul li.auto-box {
        height: 25px;
    }
    .web-table-heading {
        padding: 10px 20px !important;
        font-size: 16px !important;
    }
    .device button {
        height: 38px;
    }
    .select-main {
        padding: 0 0 15px;
    }
    .table-col .yes-no {
        padding: 7px 15px;
    }
    .table-col .yes-no li {
        height: 25px;
    }
    .web-table-col {
        padding: 7px 20px !important;
    }
}


@media screen and (max-width:1499px) {
    .main-dashboard {
        padding: 0 25px;
    }

    .dashboard-customers {
        width: 50%;
        overflow-x: auto;
    }

    .dashboard-left .dashboard-order-details .dashboard-orders,
    .dashboard-drivers {
        width: 650px;
    }

    .table-data-main {
        width: 100%;
        display: inline-block;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .table-data {
        width: 1320px;
    }

    .action-main {
        top: -42px;
        right: 70px;
    }
}

@media screen and (max-width:1365px) {
    .dashboard-left .dashboard-main-details {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 18px;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left {
        width: 48%;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-right {
        width: 49%;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col {
        gap: 18px;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col {
        width:48%;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col .dashboard-details-heading {
        height: auto;
    }

    .setting-main .setting-details .basic-detail .profile-upload .profile-img {
        width: 120px;
        height: 120px;
    }

    .setting-main .setting-details {
        padding: 20px;
    }

    .setting-main .setting-details .basic-detail .profile-details {
        width: calc(100% - 120px);
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail .content-detail h4,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail .content-detail h4 {
        font-size: 26px;
    }
    .dashboard-left .dashboard-content-news .dashboard-details-right {
        width: 50% !important;
    }
    .dashboard-left .dashboard-content-news .dashboard-post {
        width: 48% !important;
    }
}

@media screen and (max-width:1279px) {
    .dashboard-input-left {
        max-width: 430px;
    }

    .admin-dashboard.menu-active {
        position: fixed;
    }

    /* login pages */
    .dashboard-input-left {
        max-width: 430px;
    }

    .main-dashboard.dashboard-active {
        width: 100%;
    }

    .dashboard-input-left .login-back-btn {
        top: 30px;
    }


    .bar-btn-mobile {
        display: inline-block;
    }

    .logo-mobile {
        display: inline-block;
    }

    .dashboard-header .dashboard-heading {
        width: 100%;
    }

    .dashboard-header {
        flex-wrap: wrap;
        /* margin: 20px 0 15px; */
    }

    .bar-btn-mobile {
        display: inline-block;
        width: auto;
        order: 1;
    }

    .dashboard-header .dashboard-heading {
        width: 100%;
        order: 4;
        margin: 30px 0 0;
    }

    .logo-mobile {
        display: inline-block;
        width: auto;
        order: 2;
    }

    .dashboard-header .profile-picture {
        width: auto;
        display: flex;
        align-items: center;
        position: relative;
        order: 3;
    }

    .main-dashboard {
        width: 100%;
        padding: 0 15px;
    }

    .dashboard-menu {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 1111;
        height: 100vh;
    }

    .dashboard-menu .logo {
        justify-content: right;
    }

    .dashboard-menu.menu-active {
        opacity: 1;
        visibility: visible;
        left: 0;
        overflow-y: auto;
    }

    .overlay.menu-active {
        opacity: 1;
        visibility: visible;
    }

    .dashboard-menu .logo .tab-menu-bar a.barBtn {
        display: none;
    }

    .dashboard-menu .logo .tab-menu-bar a.closeBtn-mobile {
        display: block;
        margin: 20px 0 0;
    }

    .dashboard-menu.menu-active .logo .logo-img {
        display: none;
    }

    .dashboard-menu.menu-active .dashboard-options {
        margin: 30px 0 0;
    }

    .admin-dashboard.menu-active {
        position: fixed;
        height: 100%;
    }

    .setting-main .setting-details {
        max-width: inherit;
    }
}

@media screen and (max-width:1099px) {
    /* .dashboard-input-left .logo {
        margin: 0 0 45px 0px;
    } */

    /* login pages */

    .dashboard-input-left {
        max-width: 390px;
    }

    .dashboard-input-left .login-input-details .input-group input {
        height: 55px;
    }

    .dashboard-input-left .login-input-details .login-btn button {
        height: 55px;
    }

    .dashboard-input-left .login-input-details .input-group input[type="checkbox"] {
        width: 15px;
        height: 15px;
    }

    .dashboard-input-left .login-input-details .otp-input input {
        width: 55px;
        height: 55px;
    }

    #Dashboard-login-main {
        height: 100vh;
    }

    .setting-main .setting-details {
        max-width: 100%;
    }

.css-1eeu2ag{width: 100%;}

}

@media screen and (max-width:1023px) {
    .dashboard-input-left .login-input-details .login-btn button {
        height: 55px;
    }

    .dashboard-input-left .login-input-details .input-group input {
        height: 55px;
    }

    .dashboard-input-left .login-input-details .input-group input[type="checkbox"] {
        width: 18px;
        height: 18px;
    }

    /* login pages */
    .dashboard-input-left .login-back-btn {
        top: 20px;
    }

    .feedback-main {
        flex-wrap: wrap;
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail {
        padding: 30px 15px;
    }
}

@media screen and (max-width:991px) {
    .activation-main {
        height: 100%;
    }
    .col-boxes .col-item {
        font-size: 30px;
        padding: 40px 0;
    }
    .col-boxes-main {
        height: 100vh;
    }
    .col-boxes {
        gap: 25px;
    }
    .loaction-list-main .sales-entry-head .incident-select li {
        width: 126px;
    }
    .add-plan-main .loaction-list-head {
        padding: 15px;
    } 
    .add-plan-main .add-plan-detail {
        padding: 15px;
    }
    .add-plan-main .add-plan-detail .input-group {
        grid-template-columns: repeat(2, 1fr);
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field label {
        font-weight: 500;
        margin: 0 0 5px;
    }
    .loaction-list-main .issue-main .head h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .dashboard-header {
        padding: 15px 0;
    }
    .incident-user-info .head {
        padding: 15px;
    }
    .incident-user-info .info {
        padding: 15px;
        gap: 20px;
        justify-content: space-between;
    }
    .incident-user-info .info .details {
        width: calc(100% - 150px);
    }
    .incident-user-info .info .details .input-group .input-field input {
        height: 45px;
    }
    .incident-user-info .info .profile {
        width: 150px;
        height: 150px;
    }
    .incident-user-info .info .details .input-group {
        gap: 15px;
    }
    .incident-user-info .info .details .input-group .input-field {
        width: 48%;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field input {
        height: 45px;
    }
    .loaction-list-main .filter-bar-main {
        overflow-x: auto;
        gap: 20px;
    }
    .user-info-main {
        width: 90%;
    }
    .user-info-main .head {
        padding: 15px;
    }
    .user-info-main .info-details .basic-info .input-group .input-field input {
        height: 55px;
    }
    .user-info-main .head h3 {
        line-height: 28px;
    }
    .user-info-main .info-details {
        padding: 20px;
        gap: 20px;
    }
    .user-info-main .info-details .basic-info .input-group .input-field {
        width: 47%;
        margin: 0;
    }
    
    .setting-main .setting-details .basic-detail {
        flex-wrap: wrap;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images span {
        height: 150px;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images video {
        height: 150px !important;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group {
        flex-wrap: wrap;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field {
        width: 48%;
    }
    .loaction-list-main .issue-main .head {
        padding: 15px !important;
    }
    .loaction-list-main .issue-main .issue-details-main {
        padding: 15px;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-text {
        width: 100%;
    }
    .upload-media {
        flex-wrap: wrap;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs {
        width: 100% !important ;
    }
    .loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss {
        width: 176px !important;
    }
    .loaction-list-main .issue-main {
        padding: 0 0 20px;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images label, .loaction-list-main .issue-main .issue-details-main .uploaded-imgs label {
        gap: 10px;
        justify-content: left !important;
    }
    .react_pagination {
        margin: 0 !important;
    }
    .loaction-list-main .loaction-list-head .incident-select li {
        padding: 15px 25px;
        font-size: 18px;
    }
    .select-main {
        flex-wrap: wrap;
        row-gap: 30px;
        gap: 30px;
        justify-content: space-between;
        padding: 20px 15px;
    }
    .select-main .select-box ul.arrow-btns li.number-count {
        padding: 10px 60px;
    }
    .select-main .select-box {
        width: 30%;
    }
    .header {
        padding: 15px;
    }
    .header .logo a img {
        width: 150px;
        height: auto;
    }
    .total-payout-main {
        justify-content: left;
        padding: 20px 0;
    }
    .select-main .select-box h2 span {
        font-size: 24px;
    }
    .total-payout-main .total b {
        font-size: 18px;
        padding: 16px 0 0;
    }
    .total-payout-main .total {
        height: 100px;
    }
    .total-payout-main .total .head h3 {
        font-size: 18px;
    }
    td.table-col {
        padding: 15px 25px;
        font-size: 16px;
    }
    th.heading {
        padding: 20px;
        font-weight: 600;
        font-size: 16px;
    }
    .device {
        width: 210px;
    }
    .select-main .select-box ul li {
        font-size: 16px;
    }
    .select-main .select-box ul {
        padding: 8px 14px;
    }
    #Dashboard-login-main {
        margin: 0 auto;
        height: 100vh;
    }
    .header .profile> a {
        width: 45px;
        height: 45px;
    }
    .dashboard-input-left .logo a img {
        width: 200px;
    }
    .dashboard-input-left {
        padding: 50px 0;
    }
    .select-main .select-box h2 {
        font-size: 14px;
        margin: 0 0 10px;
    }
    .dashboard-input-left .logo {
        margin: 0 0 25px 0;
    }
    .dashboard-input-left .login-input-details p {
        margin: 8px 0 15px 0;
    }
    .dashboard-input-left .login-input-details h2 {
        font-size: 27px;
    }
    .dashboard-input-left .login-input-details .input-group a {
        font-size: 16px;
    }
    .dashboard-input-left .login-input-details .input-group input {
        height: 60px;
    }
    .dashboard-input-left .login-input-details .login-btn button {
        height: 60px;
    }
    .dashboard-input-left .login-input-details .otp-input input {
        width: 60px;
        height: 60px;
    }
    .device .dropdown-model {
        width: 210px;
        height: 280px
    }
    .device .dropdown-model ul li#1 {
        padding-bottom: 0;
    }
    #member-profile-main {
        padding: 0 15px;
    }
    .table-data-dropdown{
        overflow-x:scroll;
        overflow-y: unset;
        padding: 15px 30px;
    }
    .web-table-heading {
        font-size: 14px !important;
    }
    .table-col .yes-no li {
        font-size: 14px;
        height: 25px;
    }
    .device button {
        height: 35px;
    }
    .profile-main {
        padding: 40px 15px;
        justify-content: left;
        flex-wrap: wrap;
    }
    .profile-main .profile-details-main .profile-img {
        margin: 0;
        width: 100%;
    }
    .profile-main .profile-details-main {
        margin: 0 0 50px;
        width: 180px;
    }
    .profile-main .profile-details-main .profile-img> img {
        height: 180px;
    }
    .profile-main .profile-input-detail {
        width: 100%;
    }
    .order-details-inprogress .order-tracking-main .tracking-left .details {
        padding: 15px;
    }
    .order-inspection-main .order-tracking-main .tracking-left .order-photos .img span {
        width: 200px;
        height: 125px;
    }
    .order-tracking-main {
        flex-wrap: wrap;
    }
    .order-tracking-main .tracking-left {
        width: 100%;
    }
    .order-tracking-main .tracking-left .details {
        padding: 15px;
    }
    .order-tracking-main .tracking-left .head {
        padding: 23px 15px;
    }
    .order-tracking-main .tracking-right {
        margin-left: auto;
    }
    .new-order-details .order-tracking-main .tracking-left {
        flex-wrap: wrap;
        gap: 0;
        padding: 15px;
    }
    .order-tracking-main .tracking-left .order-photos .img span {
        width: 200px;
        height: 200px;
    }
    .new-order-details .order-tracking-main .tracking-left .details {
        width: 100%;
    }
    .booking-details-main {
        flex-wrap: wrap;
    }
    .sought-details {
        flex-wrap: wrap;
    } 
    .sought-details .sought-form, .sought-details .physical-detail {
        width: 48%;
    }
    .sought-details .sought-time .other-detail .input-group .input-field {
        width: 100%;
    }
    .sought-details .sought-time {
        width: 100%;
    }
    .suitor-other-details .physical-detail,
    .suitor-other-details .other-detail {
        padding: 15px;
    }
    .suitor-details-main .setting-details .basic-detail {
        gap: 20px;
    }
    .suitor-other-details .other-detail .input-group .input-field {
        width: 47%;
    }
    .suitor-other-details .other-detail .input-group {
        flex-wrap: wrap;
    }
    .suitor-other-details .other-detail .input-group .input-field .passport-field {
        flex-wrap: wrap;
    }
    .suitor-other-details .other-detail .input-group .input-field .passport-field .input-field {
        width: 100% !important;
    }
    .login-img-right {
        display: none;
    }

    .dashboard-input-left {
        margin: 45px auto 0;
        text-align: center;
    }

    .dashboard-input-left .login-input-details .input-group {
        justify-content: center;
    }

    /* .dashboard-input-left .logo a img {
        width: 200px;
    } */

    .dashboard-input-left .logo {
        margin: 0 0 20px 0;
    }

    .dashboard-input-left .login-input-details .otp-input input {
        width: 60px;
        height: 60px;
    }

    /* login pages */
    .login-img-right {
        display: none;
    }

    .dashboard-input-left .login-back-btn {
        top: 10px;
    }

    /* .dashboard-input-left .logo a img {
        width: auto;
    } */

    .dashboard-input-left {
        width: 100%;
    }

    .dashboard-input-left .login-input-details .input-group {
        justify-content: center;
    }

    .dashboard-input-left {
        text-align: center;
    }

    .dashboard-input-left .login-input-details p {
        margin-bottom: 20px;
    }

    .dashboard-input-left {
        max-width: 430px;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col {
        width: 48%;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-right {
        width: 100%;
    }

    .vendor-main {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .vendor-main .vendor-information {
        width: 100%;
        max-width: inherit;
    }

    .vendor-main .vendor-information .basic-detail .profile-details .block-btn button {
        width: 40px;
        height: 40px;
    }

    .vendor-main .vendor-information .basic-detail .profile-details .block-btn button img {
        width: 16px;
    }

    .vendor-main {
        height: auto;
    }

    .information-main .info-details .input-group {
        flex-wrap: wrap;
        gap: 21px;
    }

    .information-main .info-details .input-group .input-field {
        width: 48%;
    }

    .setting-main .setting-details .basic-detail .profile-details {
        width: 100%;
    }

    .reject-services-main {
        width: 700px;
    }

    .create-admin-main {
        width: 680px;
    }

    .create-admin-main .admin-profile-main .admin-profile {
        width: 140px;
    }

    .create-admin-main .admin-profile-main .admin-profile img {
        height: 140px;
    }

    .create-admin-main .admin-profile-main {
        flex-wrap: wrap;
    }

    .create-admin-main .admin-profile-main .admin-detail {
        width: 100%;
    }

    .contact-detail .setting-details {
        max-width: 100% !important;
    }

    .contact-detail .setting-details .basic-detail .profile-details .input-group .input-field {
        width: 50%;
    }

    .services-main .service-type-main .service {
        width: 100%;
    }

    .loaction-list-main .request-selection ul {
        width: max-content;
    }

    .loaction-list-main .request-selection {
        max-width: 900px;
        overflow-x: scroll;
    }

    .doctors-details .doc-main .doc {
        width: max-content;
    }

    .loaction-list-main .blog-detail-main .content .video-content {
        flex-wrap: wrap;
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail {
        width: 100%;
    }

    .loaction-list-main .blog-detail-main .content .video-content .video-file {
        width: 100%;
    }
    .dashboard-left .dashboard-main-details .dashboard-details-left {
        width: 100%;
    }
    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col {
        justify-content: space-between;
        margin: 0 0 20px;
    }
    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col {
        padding:0;
    }
    .dashboard-left .dashboard-content-news .dashboard-details-right ,
    .dashboard-left .dashboard-content-news .dashboard-post{
        width: 100% !important;
    }
    .web-signUp-main {
        height: auto !important;
    }
    
}

@media screen and (max-width:767px) {
    .dashboard-input-left {
        width: 100%;
    }

    .dashboard-input-left .login-input-details .otp-input input {
        width: 55px;
        height: 55px;
    }

    .customer-detail .setting-details .basic-detail {
        flex-wrap: wrap;
        gap: 20px;
    }

    .setting-main .setting-details .basic-detail .profile-details {
        width: 100%;
    }

    .creat-main,
    .creat-category-main {
        max-width: 580PX;
    }



    .setting-main .setting-details .basic-detail {
        flex-wrap: wrap;
        padding: 0 0 25px;
    }

    .loaction-list-main .loaction-list-head {
        flex-wrap: wrap;
    }

    .loaction-list-head button.Export-btn {
        margin: 20px 0;
    }

    .reject-services-main {
        width: 576px;
    }

    .create-admin-main {
        width: 550px;
    }

    .create-admin-main .create-head {
        width: 100%;
    }

    .customer-detail .setting-details .basic-detail .profile-details .input-group {
        flex-wrap: wrap;
    }

    .setting-main .setting-details .save-btn {
        margin: 0;
    }

    .contact-detail .setting-details .basic-detail .profile-details .input-group .input-field {
        width: 100%;
    }

    .setting-main .setting-details .basic-detail .profile-details .input-group .input-field {
        width: 100%;
    }

    .request-document .doc-main .doc {
        width: 165px;
    }

    .request-document .doc-main .doc .Document-name {
        padding: 15px 0;
    }

}

@media screen and (max-width:639px) {
    .loaction-list-head ul {
        width: 520px;
        overflow-x: auto;
    }

    .loaction-list-head ul li a {
        padding: 15px 0;
    }

    .dashboard-left .dashboard-order-details {
        flex-wrap: wrap;
    }

    .dashboard-customers {
        width: 100%;
    }

    .loaction-list-main .loaction-list-head {
        padding: 15px 15px 0 15px;
        flex-wrap: wrap;
    }

    .loaction-list-main .loaction-list-head .add-location {
        margin: 20px 0 0;
    }

    .loaction-list-main .loaction-list-head .add-location {
        width: 100%;
    }

    .next-previous-slide {
        flex-wrap: wrap;
        justify-content: center;
    }

    .next-previous-slide .slides-button {
        width: 100%;
        text-align: center;
        margin: 25px 0 0;
    }

    .customer-detail .setting-details .basic-detail .profile-details .input-group {
        flex-wrap: wrap;
        margin: 0 0 25px;
    }

    .setting-main .setting-details .basic-detail .profile-details .input-group .input-field {
        width: 100%;
    }

    .customer-detail .setting-details .basic-detail .profile-details .customer-info .block-btn button {
        width: 40px;
        height: 40px;
    }

    .customer-detail .setting-details .basic-detail .profile-details .customer-info .block-btn button img {
        width: 16px;
    }

    .customer-detail .setting-details .basic-detail .profile-details .sms-update {
        width: 255px;
    }

    .block-main,
    .logout-main {
        max-width: 300px;
        padding: 15px 26px 20px;
    }

    .information-main .info-details .input-group .input-field {
        width: 100%;
    }

    .information-main .info-details {
        padding: 25px;
    }

    .information-main .info-head {
        padding: 23px 25px;
    }

    .feedback-main .employee-feed .employee-box .service-pictures .services-img {
        flex-wrap: wrap;
    }

    .feedback-main .employee-feed .employee-head {
        padding: 23px 25px;
    }

    .feedback-main .employee-feed .employee-box {
        padding: 25px;
    }

    .feedback-main .employee-feed .employee-box .service-pictures {
        margin: 25px 0 0;
    }

    .feedback-main .employee-feed .employee-box .customer-rating {
        margin: 25px 0 0;
    }

    .main-dashboard .dashboard-table-head {
        flex-wrap: wrap;
    }

    .add-recovery-btn,
    .add-subadmin-btn {
        width: 100%;
        margin: 20px 0 0;
    }

    .doctors-details .doc-main .doc {
        width: 100%;
        padding: 15px;
    }

    .request-document .doc-main .doc .doc-file {
        height: 150px;
    }
}

@media screen and (max-width:575px) {
    .dashboard-input-left {
        padding: 0 15px;
        margin: 0;
    }

    .dashboard-input-left .login-input-details .login-btn button {
        height: 45px;
    }

    .dashboard-input-left .login-input-details .input-group input {
        height: 45px;
        padding: 0 40px;
    }

    .dashboard-input-left .login-input-details .input-group span.icon img {
        width: 20px;
    }

    .dashboard-input-left .login-input-details .input-group span.icon img.mobile-icon {
        width: 16px;
    }

    .dashboard-input-left .login-input-details .input-group span.icon {
        left: 4px;
    }

    .dashboard-input-left .logo {
        margin: 0 0 10px 0;
    }

    /* .dashboard-input-left .logo a img {
        width: 170px;
    } */

    .dashboard-input-left .login-input-details p {
        margin: 8px 0 25px 0;
    }

    .dashboard-input-left .login-input-details .otp-input input {
        width: 45px;
        height: 45px;
    }

    /* login pages */

    .dashboard-input-left .logo {
        margin: 0 0 10px 0px;
    }

    /* 
    .dashboard-input-left .logo a img {
        width: 150px;
    } */

    .add-plan-detail .line-section .bottom-class {
        bottom: -187px !important;
    }
    .add-plan-detail .line-section .bottom-class-model {
        bottom: -115px !important;
    }
    .add-plan-detail .line-section .bottom-class-model-carrier {
        bottom: -220px !important;
    }
    .add-plan-detail .line-section .bottom-class-port {
        bottom: -215px !important;
    }
 .add-plan-detail .line-section .bottom-class-line {
     bottom: -187px !important;
 }

 .add-plan-detail .line-section .bottom-class-model-line {
     bottom: -110px !important;
 }

 .add-plan-detail .line-section .bottom-class-model-carrier-line {
     bottom: -215px !important;
 }

 .add-plan-detail .line-section .bottom-class-port-line {
     bottom: -215px !important;
 }
    .carrier-search-main-date{
        bottom: -220px !important;
    }
    .dashboard-input-left .login-input-details h2 {
        font-size: 22px;
    }

    .dashboard-input-left .login-input-details p {
        font-size: 14px;
        margin: 8px 0 25px 0;
    }

    .information-main .info-details .input-group.my {
        margin: 0 0 0;
    }

    .dashboard-input-left .login-input-details .input-group input {
        height: 45px;
        padding: 0 45px;
    }

    .dashboard-input-left .login-input-details .login-btn button {
        height: 45px;
    }

    .dashboard-input-left .login-input-details .otp-input input {
        width: 40px;
        height: 40px;
    }

    .dashboard-input-left .login-input-details .input-group span.icon {
        left: 8px;
    }

    .dashboard-input-left .login-input-details .input-group span.icon img {
        width: 20px;
    }

    .dashboard-input-left .login-input-details .input-group span.icon img.mobile-icon {
        width: 15px;
    }

    .dashboard-input-left .login-input-details .resend-otp p a {
        margin: 0;
        font-size: 14px;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col {
        width: 100%;
        padding: 0;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-right {
        width: 100%;
    }

    .dashboard-header .dashboard-heading h2,
    .dashboard-header .dashboard-heading a.dashboard-back,
    .main-dashboard .dashboard-table-head h2,
    .services-main .service-type-main .service .head h2 {
        font-size: 22px;
    }

    .filter-btn {
        width: 100%;
        margin: 15px 9px 0 0;
    }

    .filter-main {
        width: 300px;
    }

    .filter-main .filter-status .input-group input {
        width: 18px;
    }

    .filter-main .filter-apply button {
        height: 40px;
        font-size: 14px;
    }

    .creat-main,
    .creat-category-main {
        max-width: 320px;
        padding: 15px;
    }

    .creat-main .input-group {
        flex-wrap: wrap;
    }

    .creat-main .input-group .input-field {
        width: 100%;
    }

    .creat-main .input-group .input-field input {
        height: 40px;
    }

    .creat-main .creat-btn button {
        height: 40px;
        font-size: 14px;
    }

    .customer-profile img {
        width: 40px;
        height: 40px;
    }

    td.table-col {
        padding: 15px;
    }

    .setting-main .setting-details .basic-detail .profile-details {
        width: 100%;
    }

    .vendor-main .vendor-information .basic-detail {
        flex-wrap: wrap;
    }

    .vendor-main .vendor-information .vendor-details .input-group .input-field {
        width: 100%;
    }

    .vendor-main .vendor-information .vendor-details .input-group {
        flex-wrap: wrap;
    }

    .setting-main .setting-details .basic-detail .profile-details .input-group {
        flex-wrap: wrap;
        margin: 20px 0 0;
    }

    .setting-main {
        height: 100vh;
    }

    .setting-main .setting-details .basic-detail {
        padding: 0 0 0;
        margin: 0 0;
    }

    .customer-detail .setting-details .basic-detail .profile-details .customer-info {
        flex-wrap: wrap;
    }

    .customer-detail .setting-details .basic-detail .profile-details .customer-info .customer-name {
        margin: 0;
    }

    .setting-main .setting-details p {
        line-height: 20px;
        margin: 0 0 15px;
    }

    .setting-main .setting-details .basic-detail .profile-upload .profile-img .input-file {
        width: 30px;
        height: 30px;
    }

    .setting-main .setting-details .basic-detail .profile-upload .profile-img .input-file img {
        width: 18px;
    }

    .setting-main .setting-details .save-btn button,
    .service-info .add-services-btn button {
        height: 40px;
    }

    .loaction-list-main .loaction-list-head {
        padding: 0 15px 0 15px;
    }


    .information-main .info-details,
    .feedback-main .employee-feed .employee-box {
        padding: 20px 15px 15px 15px;
    }

    .information-main .info-head,
    .feedback-main .employee-feed .employee-head {
        padding: 20px;
    }

    .feedback-main .employee-feed .employee-box .service-pictures .services-img,
    .feedback-main .employee-feed .employee-box .service-pictures .services-head {
        gap: 10px;
    }

    .service-product-main {
        width: 320px;
        height: 500px;
        overflow-y: auto;
    }

    .service-product-main .product-head {
        padding: 15px;
    }

    .service-product-main .product-details {
        padding: 15px;
    }

    .service-img-main {
        margin: 20px 0 0;
    }

    .service-img-main .product-upload {}

    .service-img-main .product-upload .uploaded-img {
        width: 110px;
    }

    .service-img-main .product-upload .uploaded-img img {
        height: 110px;
    }

    .service-img-main .product-upload .add-img {
        width: 110px;
        height: 110px;
    }

    .service-product-main .product-btn {
        padding: 15px;
    }

    .service-product-main .product-btn button,
    .information-main .info-details .complete-btn button,
    .customer-detail .setting-details .basic-detail .profile-details .save-btn button {
        height: 40px;
    }

    .feedback-main .employee-feed .employee-box .service-pictures .services-img .img-col {
        width: 110px;
    }

    .feedback-main .employee-feed .employee-box .service-pictures .services-img .img-col img {
        height: 110px;
    }

    .feedback-main .employee-feed .employee-box .service-pictures .services-img .img-col span.delete-icon {
        bottom: 12px;
    }

    .customer-detail .setting-details .basic-detail .profile-details .save-btn {
        margin: 30px 0 0;
    }

    .setting-main .setting-details .basic-detail .profile-details .input-group .input-field input {
        height: 45px;
        font-weight: normal;
    }

    .customer-detail .setting-details .basic-detail .profile-details .input-group.mb {
        margin: 0 0 25px;
    }

    .setting-main {
        height: auto;
    }

    .clients-details-head .dashboard-heading {
        margin: 15px 0 0;
    }

    .setting-main .setting-details .save-btn {
        margin: 10px 0 0;
    }

    .main-dashboard {
        height: 100vh;
    }

    .clients-details-head {
        height: auto;
    }

    .clients-main-dashboard,
    .service-main-dashboard,
    .complete-service-main {
        height: auto;
    }

    .loaction-list-main .loaction-list-head .search-bar {
        max-width: none;
    }

    .loaction-list-main .loaction-list-head .search-bar input {
        width: 270px;
    }

    .loaction-list-main .loaction-list-head .add-service {
        margin: 15px auto;
        width: 90%;
    }
    .loaction-list-main .loaction-list-head .incident-select li {
        width: 50%;
        font-size: 16px;
        padding: 15px 0;
        text-align: center;
    }
    th.heading {
        padding: 15px;
        font-size: 14px;
    }
    .loaction-list-main .incident-select-head {
        padding: 0;
    }

    .service-info .feedback-main .employee-feed .employee-box .service-pictures .services-img .img-col {
        width: 110px;
        height: 110px;
    }

    .service-info .add-services-btn {
        margin: 30px 0 0;
    }

    .time-period-main .timing {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .reject-services-main {
        width: 320px;
        padding: 15px;
    }

    .reject-services-main .service-head {
        margin: 0 0 30px;
    }

    .reject-services-main .input-group {
        margin: 0 0 20px;
    }

    .reject-services-main .reject-btn button {
        height: 40px;
    }

    .time-period-main .timing span {
        width: 100%;
    }

    .time-period-main .timing .start-time,
    .time-period-main .timing .end-time {
        width: 100%;
    }

    .change-password-main {
        width: 320px;
        padding: 15px;
    }

    .change-password-main .password-btn button {
        height: 40px;
    }

    .main-dashboard .dashboard-table-head a {
        font-size: 16px;
    }

    /* .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col .dashboard-details-col .dashboard-details-heading {
        margin: 0 0 20px;
    } */

    .dashboard-left .dashboard-main-details .dashboard-details-left .dashboard-main-col {
        margin: 0;
    }

    .dashboard-input-left .login-input-details .input-group a {
        margin: 0 0 15px;
    }

    #Dashboard-login-main {
        justify-content: center;
    }

    .otp-main,
    .password-change-main,
    .recovery-home-add-main {
        width: 360px;
        top: 15px;
    }

    .otp-main .otp,
    .password-change-main .otp,
    .recovery-home-add-main .otp {
        padding: 15px;
    }

    .otp-main,
    .password-change-main,
    .recovery-home-add-main {
        width: 320px;
        top: 15px;
    }

    .dashboard-input-left .login-input-details .save-btn {
        margin: 20px 0 0;
    }

    .information-main .info-details .house-img-main .house-head h2 {
        flex-wrap: wrap;
    }

    .information-main .info-details .house-img-main .house-head h2 span {
        width: 100%;
        margin: 5px 0 7px 0;
    }

    .information-main .info-details .house-img-main .upload-img-main .upload-img {
        width: 120px;
        height: 120px;
    }

    .information-main .info-details .add-house button {
        font-size: 14px;
        height: 40px;
        width: 200px;
    }

    .information-main .info-details .house-img-main .upload-img-main {
        gap: 25px;
        justify-content: space-between;
    }

    .information-main .info-details .house-img-main .upload-img-main .upload-img button.edit-img {
        width: 40px;
        height: 40px;
    }

    .create-admin-main {
        width: 320px;
        height: 500px;
        overflow-y: auto;
    }

    .create-admin-main .admin-profile-main .admin-detail .input-group {
        flex-wrap: wrap;
    }

    .create-admin-main .admin-profile-main .admin-detail .input-group .input-field {
        width: 100%;
    }

    .create-admin-main .create-btn button {
        width: 190px;
        height: 40px;
        font-size: 14px;
    }

    .dashboard-header .profile-picture .profile-img a img {
        width: 45px;
        height: 45px;
    }

    .customer-detail .setting-details .basic-detail .profile-details .customer-info .block-btn button,
    .customer-detail .setting-details .basic-detail .profile-details .customer-info .edit-btn button,
    .setting-details .edit-btn button {
        width: 40px;
        height: 40px;
    }

    .next-previous-slide {
        margin: 20px 0;
    }

    .subadmin-dashboard {
        height: 100%;
    }

    .main-dashboard .dashboard-table-head {
        margin: 20px 0 31px;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-left .head {
        padding: 15px 5px;
    }

    .dashboard-left .dashboard-main-details .dashboard-details-right .graph .graph-head h4,
    .dashboard-left .dashboard-main-details .dashboard-details-left .head h4 {
        font-size: 18px;
    }

    .services-main .service-type-main .service .head {
        padding: 13px 0 13px 15px;
    }

    .services-main .service-type-main .service .id {
        padding: 22px 15px;
    }

    .services-main .service-type-main .service ul li {
        padding: 13px 15px;
    }

    .services-main .service-btn {
        gap: 10px;
        justify-content: left;
    }

    .add-service-main,
    .add-insurance-main {
        width: 300px;
    }

    .loaction-list-main .request-selection ul li {
        padding: 15px 35px 15px 0;
        font-size: 16px;
    }

    .request-main .setting-details .basic-detail .profile-details .profile-head {
        flex-wrap: wrap;
    }

    .request-document h3,
    .doctors-details h3 {
        font-size: 22px;
    }

    .request-main .setting-details .basic-detail .profile-details .profile-head .reject-approve-btn button {
        width: 110px;
        height: 40px;
    }

    .logo-mobile a img {
        width: 150px;
        padding: 0 0;
    }

    .dashboard-header {
        padding: 20px 0 15px;
    }

    .loaction-list-main .blog-head {
        padding: 11px 15px;
    }

    .loaction-list-main .blog-head .right button,
    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .thumbnail-img button.edit-btn,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .thumbnail-video button.edit-btn {
        width: 35px;
        height: 35px;
    }

    .loaction-list-main .blog-head .right button img,
    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .thumbnail-img button.edit-btn img,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .thumbnail-video button.edit-btn img {
        width: 15px;
    }

    .loaction-list-main .blog-detail-main {
        padding: 15px;
    }

    .loaction-list-main .blog-detail-main .title input {
        height: 40px;
        padding: 0 15px;
        font-size: 14px;
    }

    .loaction-list-main .blog-detail-main .content .content-data {
        padding: 15px;
    }

    .loaction-list-main .blog-detail-main .content .content-data p {
        font-size: 14px;
    }

    .loaction-list-main .blog-detail-main .upload-blog button {
        height: 40px;
        width: 140px;
        font-size: 13px;
    }

    .dashboard-header .dashboard-heading a.dashboard-back img {
        margin: 0 10px 0 0;
        width: 15px;
    }

    .loaction-list-main .blog-detail-main .content {
        margin: 20px 0 0;
    }

    .bar-btn-mobile span {
        width: 45px;
        height: 45px;
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail {
        padding: 15px;
        border-radius: 10px;
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail .content-detail h4,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail .content-detail h4 {
        font-size: 18px;
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail .content-detail h4 img,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail .content-detail h4 img {
        width: 25px;
    }

    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail .content-detail p,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail .content-detail p,
    .loaction-list-main .blog-detail-main .content .video-content .thumbnail .upload-thumbnail .content-detail span,
    .loaction-list-main .blog-detail-main .content .video-content .video-file .upload-thumbnail .content-detail span {
        font-size: 16px;
        margin: 10px 0 65px;
    }
    .loaction-list-main .blog-detail-main .content .video-content {
        gap: 24px;
    }
    .loaction-list-main .blog-detail-main .content .video-content .video-file .thumbnail-video button.play-btn img {
        width: 60px;
    }
    .setting-main .setting-details .basic-detail .profile-details .input-group .input-field {
        width: 100% !important;
    }
    .suitor-other-details {
        flex-wrap: wrap;
    }
    .suitor-other-details .physical-detail {
        width: 100%;
    }
    .suitor-other-details .other-detail .input-group .input-field {
        width: 100% !important;
    }
    .sought-details .sought-form, .sought-details .physical-detail {
        width: 100%;
    }
    .available-time th , .available-time td {
        padding: 10px 15px;
        font-size: 16px;
    }
    .sought-details .sought-time .other-detail {
        padding: 15px;
    }
    .sought-details .sought-form .physical-detail {
        padding: 15px;
    }
    .setting-main .setting-details {
        padding: 15px;
    }
    .suitor-other-details input {
        height: 45px;
    }
    .suitor-details-main .setting-details .basic-detail .profile-details .head .block-btn {
        width: 40px;
        height: 40px;
    }
    .suitor-details-main .setting-details .basic-detail .profile-details .head .block-btn img {
        width: 18px;
    }
    .suitor-other-details h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 15px;
    }
    .booking-details-main .setting-details h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin: 0 0 10px;
    }
    .order-tracking-main .tracking-left .details .input-group {
        flex-wrap: wrap;
    }
    .order-tracking-main .tracking-left .details .input-group .input-field {
        width: 100%;
    }
    .order-tracking-main .tracking-left .order-photos .img {
        gap: 20px;
    }
    .order-inspection-main .order-tracking-main .tracking-left .order-photos .img span {
        width: 44%;
        height: 95px;
    }
    .order-tracking-main .tracking-left .head {
        padding: 15px;
    }
    .order-shipped-main .order-tracking-main .tracking-left .details .input-group .input-field {
        width: 100%;
    } 
    .order-shipping-main .order-tracking-main .tracking-left .details .input-group .input-field {
        width: 100%;
    }
    .order-shipping-main .order-tracking-main .tracking-left .details .input-group .input-field .input-group {
        width: 100%;
    }
    .order-shipping-main .order-tracking-main .tracking-left .details .input-group {
        gap: 0;
    }
    .add-employee-main, .edit-employee-main {
        width: 90%;
        height: 80vh;
        overflow-y: auto;
    }
    .add-employee-main .add-employee-details .profile, .edit-employee-main .add-employee-details .profile {
        width: 120px;
    }
    .add-employee-main .add-employee-details .profile .profile-img > img, .edit-employee-main .add-employee-details .profile .profile-img > img {
        height: 120px;
    }
    .add-employee-main .head, .edit-employee-main .head {
        margin: 0 0 15px;
    }
    .add-employee-main .add-employee-btn, .edit-employee-main .add-employee-btn {
        margin: 10px 0 0;
    }
    .add-employee-main .add-employee-details, .edit-employee-main .add-employee-details {
        flex-wrap: wrap;
    }
    .add-employee-main .add-employee-details .employee-info-fill, .edit-employee-main .add-employee-details .employee-info-fill {
        width: 100%;
    }
    .add-employee-main .add-employee-details .employee-info-fill .input-group, .edit-employee-main .add-employee-details .employee-info-fill .input-group {
        flex-wrap: wrap;
    }
    .add-employee-main .add-employee-details .employee-info-fill .input-group .input-field, .edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field {
        width: 100%;
    }
    .dashboard-input-left {
        padding: 50px 15px;
    }
    .dashboard-input-left .login-input-details .input-group input {
        height: 45px;
        font-size: 16px;
    }
    .dashboard-input-left .login-input-details .input-group span.icon img {
        width: 25px;
    }
    .dashboard-input-left .login-input-details .login-btn button {
        height: 45px;
    }
    .dashboard-input-left .login-input-details .input-group a.forgot-btn {
        margin: 0;
    }
    .dashboard-input-left .login-input-details h2 {
        font-size: 23px;
    }
    .dashboard-input-left .login-back-btn {
        top: 55px;
        left: 30px;
    }
    .dashboard-input-left .login-input-details .otp-input input {
        width: 45px;
        height: 45px;
    }
    .dashboard-input-left .login-input-details .otp-input {
        margin: 0 0 10px 0;
    }
    .dashboard-input-left .login-input-details .login-btn {
        margin: 0  0 20px;
    }
    .back-btn {
        left: 15px;
        position: absolute;
    }
    .back-btn button {
        width: 40px;
        height: 40px;
    }
    .profile-main h2 {
        margin: 0 0 35px;
        font-size: 20px;
    }
    .profile-main .profile-details-main .profile-img {
        width: 140px;
    }
    .profile-main .profile-details-main .profile-img> img {
        height: 150px;
    }
    .profile-main .profile-details-main .profile-img button {
        width: 35px;
        height: 35px;
    }
    .profile-main .input-group {
        flex-wrap: wrap;
        margin: 0  0 23px;
    }
    #member-profile-main {
        padding: 0 15px;
        height: auto;
    }
    .profile-main .input-group .input-field {
        width: 100%;
    }
    .profile-main .profile-details-main label {
        margin: 5px 0 0;
        font-size: 14px;
    }
    .profile-main .input-group .input-field label {
        font-size: 14px;
        margin: 0 0 7px;
    }
    .profile-main .input-group .input-field input {
        height: 45px;
        font-size: 14px;
    }
    .change-password-main {
        width: 90%;
    }
    .change-password-main .password-btn button {
        height: 45px;
    }
    .profile-main .save-btn button {
        width: 100%;
        height: 45px;
    }
    .header .logo a img {
        width: 116px;
    }
    .profile-dropdown {
        top: 10px;
        right: 15px;
    }
    .select-main .select-box {
        width: 100%;
        text-align: left;
    }
    .total-payout-main {
        flex-wrap: wrap;
    }
    .total-payout-main .total {
        height: 100px;
        width: 100%;
    }
    .breadcrumbs {
        padding: 20px 15px;
    }
    .profile-main {
        padding: 20px 15px;
    }
    .profile-main .profile-details-main {
        margin: 0 0 30px;
        width: 150px;
    }
    .select-main .select-box h2 {
        margin: 0 0 5px;
    }
    .table-data-dropdown {
        padding: 15px 15px 0;
    }
    .web-table-heading {
        padding: 4px 15px !important;
    }
    .profile-dropdown {
        width: 210px;
    }
    .profile-dropdown .profile-info h3 {
        margin: 10px 0 0;   
        line-height: 30px;
    }
    .profile-dropdown .profile-info {
        padding: 15px 0;
    }
    .total-payout-main .total .head h3 {
        font-weight: normal;
    }
    .website-login-main .dashboard-input-left {
        padding: 50px 15px;
    }
    .website-login-main .dashboard-input-left .login-input-details .input-group span.icon img.mobile-icon {
        width: 21px;
    }
    .loaction-list-main .issue-main .head h3 {
        font-size: 18px;
        line-height: 28px;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field {
        width: 100%;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group{
        gap: 15px;
        margin: 0 0 15px;
    }
    .loaction-list-main .issue-main .new-incident-main .input-group .input-text textarea {
        padding: 10px;
        font-size: 14px;
    }
    .loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss {
        width: 150px !important;
    }
    .loaction-list-main .issue-main .new-incident-main .input-group .input-field input {
        font-size: 14px;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field label {
        margin: 0 0 4px;
        font-weight: 500;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field input {
        height: 45px;
        font-size: 14px;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-text textarea {
        padding: 10px;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs-view {
        flex-wrap: wrap;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images {
        width: 100%;
    }
    .setting-main .setting-details .basic-detail {
        gap: 0;
    }
    .web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field input {
        font-weight: normal;
        font-size: 14px;
    }
    .web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field .position-dropdown button {
        height: 45px;
        font-weight: normal;
        font-size: 14px;
    }
    .select-main {
        padding: 0 0 20px;
    }
    .select-main .select-box h2 {
        font-weight: 500;
        font-size: 14px;
    }
    .select-main .select-box h2 span {
        font-size: 22px;
    }
    .select-main .select-box h2 span {
        font-size: 24px;
    }
    .dashboard-input-left .logo a img {
        width: 100px;
    }
    .web-signUp-main .dashboard-input-left {
        padding: 20px;
    }
    .web-signUp-main .dashboard-input-left .login-input-details .input-group {
        flex-wrap: wrap;
    }
    .web-signUp-main .dashboard-input-left .login-input-details .input-group .input-field {
        width: 100%;
    }
    .web-signUp-main .dashboard-input-left .login-input-details .input-group .input-field input {
        height: 45px;
        font-size: 14px !important;
    }
    .web-signUp-main .dashboard-input-left .login-input-details .input-group input,
    .web-signUp-main .dashboard-input-left .login-input-details .login-btn button {
        height: 45px;
        font-size: 14px;
    }
    .web-signUp-main .dashboard-input-left .login-input-details .input-group button {
        height: 45px;
        font-size: 14px;
        padding: 0 15px 0 40px;
    }
    .user-info-main {
        height: 90vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .user-info-main .info-details {
        flex-wrap: wrap;
    }
    .user-info-main .info-details .basic-info {
        width: 100%;
    }
    .user-info-main .info-details .basic-info .input-group .input-field {
        width: 100%;
    }
    .user-info-main .info-details .profile {
        width: 80px;
        height: 80px;
    }
    .user-info-main .info-details .basic-info .input-group .input-field label {
        font-weight: normal;
        margin: 0 0 4px;
    }
    .permission-main .head {
        text-align: left;
        margin: 0 0 15px;
    }
    .permission-main .close-btn {
        top: 26px;
        right: 15px;
    }
    .user-info-main .info-details .basic-info .input-group .input-field input {
        height: 45px;
    } 
    .permission-main {
        width: 90%;
        padding: 15px;
    }
    .permission-main .details .profile .username h4 {
        font-size: 18px;
    }
    .action-main {
        top: 18px;
    }
    .loaction-list-main .filter-bar-main {
        padding: 0 15px 0 15px;
    }
    .setting-main .setting-details .basic-detail .profile-upload .profile-img {
        width: 90px;
        height: 90px;
    }
    .loaction-list-main .issue-main .issue-details-main .input-group .input-field span {
        font-size: 14px;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs {
        flex-wrap: wrap;
    }
    .incident-user-info {
        margin: 20px  0;
    }
    .incident-user-info .head h3 {
        font-size: 18px;
        line-height: 28px;
    }
    .incident-user-info .info {
        flex-wrap: wrap;
    }
    .incident-user-info .info .profile {
        width: 80px;
        height: 80px;
    }

    .incident-user-info .info .details {
        width: 100%;
    }
    .incident-user-info .info .details .input-group .input-field {
        width: 100%;
    }
    .incident-user-info .info .details .input-group .input-field label,
    .web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field label {
        margin: 0 0 4px;
        font-weight: normal;
    }
    .incident-user-info .info .details .input-group .input-field span {
        font-size: 14px;
    }
    .incident-user-info .info .details .input-group .input-field input {
        font-size: 14px;
    }
    .dashboard-header .profile-picture .profile-img .profile-dropdown {
        top: 30px;
        right: 15px;
        width: 230px;
    }
    .upload-media {
        gap: 20px;
    }
    .select-main .select-box ul.arrow-btns li.number-count {
        padding: 10px 115px;
    }
    .new-upload-media {
        flex-wrap: wrap;
        gap: 20px;
    }
    .logo-mobile a img {
        width: 70px;
        height: 70px;
    }
    /* .new-upload-media .drag-main .drag .details {
        max-resolution: ;
    } */
    .total-payout-main .total b {
        font-size: 30px;
    }
    .web-add-user {
        margin: 0 0 15px;
        width: 100%;
    }
    .loaction-list-main .loaction-list-head .main-column {
        width: 50%;
    }
    .loaction-list-main .loaction-list-head button.add-user {
        width: 50%;
    }
    .loaction-list-main .loaction-list-head .main-column button {
        width: 100%;
    }
    .loaction-list-main .loaction-list-head .search-bar input {
        width: 100%;
    }
    .loaction-list-main .loaction-list-head .search-bar input {
        padding: 0 35px;
    }
    .add-employee-main .add-employee-details .employee-info-fill .input-group .input-field input {
        height: 45px;
    }
    .add-employee-main .add-employee-details .employee-info-fill .input-group, .edit-employee-main .add-employee-details .employee-info-fill .input-group {
        gap: 15px;
    }
    .add-employee-main, .edit-employee-main {
        padding: 15px;
    }
    .add-employee-main .head h3, .edit-employee-main .head h3 {
        text-align: left;
        font-size: 18px;
    }
    .add-employee-main button.close-btn, .edit-employee-main button.close-btn {
        top: 15px;
    }
    .add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .calc-select button, .add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .sheet-select button {
        height: 45px;
    }
    .loaction-list-main .loaction-list-head .export-btn {
        margin: 0 0 15px;
    }
    .permission-denied-main {
        width: 90%;
    }
    .calculator-parent {
        height: 85vh;
    }
    .add-plan-main .loaction-list-head .add-service {
        width: 100%;
        margin: 5px 0 15px;
    }
    .add-plan-main .add-plan-detail .input-group {
        grid-template-columns: repeat(1, 1fr);
    }
    .add-plan-main .add-plan-detail .input-group .input-field label {
        margin: 0 0 5px;
        font-weight: normal;
    }
    .add-plan-main .add-plan-detail .input-group .input-field button, .add-plan-main .add-plan-detail .input-group .input-field input {
        height: 45px;
        font-size: 14px;
    }
    .add-plan-main {
        height: auto;
    }
    .add-plan-main .loaction-list-head h3 {
        font-size: 18px;
    }
    .loaction-list-main .sales-entry-head .incident-select li {
        padding: 10px;
        font-size: 14px;
    }
    .sales-loader {
        width: 75%;
        height: 40vh !important;
    }
    .col-boxes {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px; 
    }
    .col-boxes .col-item {
        font-size: 24px;
        padding: 32px 0;
        line-height: 45px;
    }
    .col-boxes .col-item b {
        font-weight: normal;
    }
    .activation-main .add-plan-detail .submit-btns {
        margin: 30px 0 0;
    }
    .activation-main .add-plan-detail .submit-btns button,
    .activation-main .add-plan-detail .submit-btns button.save-btn {
        width: 50%;
        font-weight: normal;
        height: 40px;
    }
    .loaction-list-main .filter-bar-main ul {
        gap: 15px;
    }
    .loaction-list-main .filter-bar-main ul li {
        width: 125px;
    }
    .loaction-list-main .filter-bar-main ul li input {
        padding: 0 5px;
    }

    .carrier-search-main ul {
        gap: 10px;
    }
    .add-plan-main .add-plan-detail {
        margin: 0 0 45px;
    }
    .carrier-search-main {
        top: auto;
        /* bottom: 45px; */
    }
    .add-carrier-main {
        width: 90%;
    }
    .add-carrier-main {
        padding: 15px;
    }
    .add-carrier-main .input-field {
        margin: 15px 0 35px;
    }
    .add-carrier-main .input-field input {
        height: 45px;
    }
    .file-button {
        width: 100%;
        margin: 10px;
        flex-wrap: wrap;
        gap: 10px;
    }

    .file-button .file-input {
        width: 100%;
        font-size: 14px;
    }
    .loaction-list-main .loaction-list-head .file-button .add-service {
        width: 100%;
        font-weight: normal;
        margin: 0;
    }
    .add-plan-main .loaction-list-head .add-service {
        width: 93px;
        margin: 10px 0;
    }
    .InovuaReactDataGrid--theme-default-light .action-main {
        top: -2px;
        right: 97px;
        left: auto;
    }
    .loaction-list-main .filter-bar-main {
        flex-wrap: wrap;
        overflow-x: unset;
        gap: 0;
        margin: 0 0 10px;
    }
    .loaction-list-main .filter-bar-main ul {
        overflow-x: auto;
        padding: 0;
    }
    .main-column {
        padding: 0;
    }
    .loaction-list-main .filter-bar-main .filter-btn-group {
        width: 100%;
    }
    .loaction-list-main .filter-bar-main .filter-btn-group>div button {
        width: 100%;
    }
    .image-modal-content {
        height: 70vh !important;
    }
    .ReactModal__Content {
        width: 90%;
        border-radius: 10px  !important;
    }
    .loaction-list-main .issue-main .issue-details-main .uploaded-imgs-view .images img {
        height: 150px  !important;
    }
        .toggle-columns-btn {
            background-color: #fe0000;
            width: 100%;
            font-weight: normal;
            margin: 0;
            margin-bottom: 10px;
        }
    
        .upload-sale-button {
            margin-left: 0;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    
        .upload-sale-button div {
            width: 100%;
        }
    
        .loaction-list-main .loaction-list-head .export-btn {
            width: 100%;
        }
    
        .file-input {
            width: 100%;
        }
}
