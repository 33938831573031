.image-modal-content {
    position: relative;
    width: 90%;
    height: 85%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
.image-modal-content img {
   width: auto;
  }
  
  .full-screen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .ReactModal__Overlay{
    z-index: 999 !important;
  }