.react_pagination {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.active_link {
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    font-size: 25px;
    font-weight: 600;
    background-color: #FF3C3C;
    color: "white";
    font-family: 'Open Sans', sans-serif;
}

.active_page {
    color: #fff;
}

.next_button_style {
    /* font-weight: 400; */
    margin-left: 18px;
    cursor: pointer;
}
.previous_button_style , .next_button_style{
    padding-top: 5px;
}

.previous_button_style {
    /* font-weight: 400; */
    margin-right: 18px;
    cursor: pointer;
}

.previous_button_style li,
.next_button_style li {
    color: #333;
}

.listStyle {
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 49px;
    font-size: 25px;
    font-weight: 600;
    background-color: white;
    color: "white";
    font-family: 'Open Sans', sans-serif;
}

.react_pagination {
    margin: 0 0 69px;
}

.active_link {
    color: #fff;
}

.react_pagination {
    margin: 0;
    gap: 13px;
}
.active_link {
    background-color: #1A1C35;
    border-radius: 6px;
    width: 28px;
    height: 28px;
    font-size: 14px;
    color: #fff !important;
}
.previous_button_style ,
.next_button_style{
    margin: 0;
}
.listStyle a {
    color: #828282;
}
.listStyle {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    font-size: 14px;
    background-color: transparent;
    color: #828282;
}

@media screen and (max-width:991px) {
    .listStyle, .active_link {
        width: 30px;
        height: 30px;
        font-size: 20px;
    }

    .previous_button_style, .next_button_style {
        margin: 0;
    }
}

@media screen and (max-width:770px) {
    .react_pagination {
        margin: 30px 0 69px;
    }
}

@media screen and (max-width:575px) {

    .listStyle,
    .active_link {
        width: 25px;
        height: 25px;
        font-size: 14px;
    }

    .react_pagination {
        margin: 0 0 69px;
        gap: 10px;
    }

    .next_button_style {
        margin-left: 0;
    }

    .previous_button_style {
        margin-right: 0;
    }
}